



































































import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import axios, { CancelTokenSource } from "axios"
import { FindCashbackUseCase } from "@/usecases"
import { Inject } from 'inversify-props'
import { DataOptions } from "vuetify"
import { Voucher } from "@/models"
import Confirmacao from '@/components/ui/Confirmacao.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import { formatarMoeda } from '@/shareds/formatadores'
import { voucherEstaExpirado } from '@/shareds/cliente-shareds'
import { AcaoDeCashback } from '@/models/AcaoDeCashback'
import type { VoucherServiceAdapter } from "@/usecases"


@Component({
	components: {
		Confirmacao,
	},
})
export default class DialogoDePendentesDeDisparo extends Vue {
	@Ref() form!: HTMLFormElement
	mostra = false
	salvando = false
	@Ref() confirmaAlteracoesDeEnvio!: Confirmacao
	@Ref() confirmaAlteracoesDeCancelamento!: Confirmacao

	cancelToken: CancelTokenSource | null = null
	@Inject('VoucherServiceAdapter')
	private voucherService!: VoucherServiceAdapter
	listaDeCashback: Voucher[] = []
	totalRegistros = -1
	acaoManutencao: string | null = null
	carregando = false
	paramsAtualizados: any
	acoesDeCashback: AcaoDeCashback [] = []
	acaoDeCashbackId: string | null = null
	findCashbackUseCase = new FindCashbackUseCase()
	acaoManutencaoFormatada: string | null = null


	formatarMoeda = formatarMoeda
	dateTimeToPtBrFormat = dateTimeToPtBrFormat
	voucherEstaExpirado = voucherEstaExpirado

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	headers = [
		{ text: 'Gerado em', value: 'dataHoraGeracao', width: 'auto' },
		{ text: 'Cliente', value: 'cliente', width: 'auto' },
		{ text: 'Valor', value: 'valor', width: 'auto' },
		{ text: 'Grupo Econômico', value: 'grupoEconomico', width: 'auto'},
		{ text: 'Mensagem', value: 'acaoManutencao', width: 'auto'},
		{ text: 'Status', value: 'statusMsg', width: 'auto'},
	]

	async mostrar(params: any) {
		this.paramsAtualizados = {
			...params, 
			page: this.paginacao.page - 1,
			sort: 'dataHoraGeracao,desc',
			statusMsg: 'PENDENTE',
			size: this.paginacao.itemsPerPage,
		}
		this.acoesDeCashback = await (this.findCashbackUseCase.findListaAcoesDeCashback({lojaId: params.lojaId}));
		this.mostra = true
	}

	cancelar() {
		this.mostra = false
		this.acaoManutencaoFormatada = null
		this.acaoManutencao = null
		this.acaoDeCashbackId = null
		this.listaDeCashback = []
	}

	async salvar(){
		try{
			const command = {
				...this.paramsAtualizados,
			}
			
			await this.voucherService.disparoDeMensagemVoucher(command);
		}catch (error: any){
			AlertModule.setError(error)
		}finally{
			this.acaoManutencaoFormatada = null
			this.mostra = false
			this.listaDeCashback = []
			this.acaoDeCashbackId = null
			this.acaoManutencao = null
		}
	}

	abrirDialogoDeConfirmacaoDeEnvio(){
		this.confirmaAlteracoesDeEnvio.mostrar()
	}

	abrirDialogoDeConfirmacaoDeCancelamento(){
		this.confirmaAlteracoesDeCancelamento.mostrar()
	}

	async buscar() {
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()
			
			if (this.acaoManutencao === "Alteração de data") {
				this.acaoManutencaoFormatada = "ALTERACAO_DATA";
			} else if (this.acaoManutencao === "Dobro de Cashback") {
				this.acaoManutencaoFormatada = "DOBRO";
			}

			this.paramsAtualizados = {
				...this.paramsAtualizados, 
				acaoManutencao: this.acaoManutencaoFormatada,
				acaoDeCashbackId: this.acaoDeCashbackId != null ? this.acaoDeCashbackId : null,
				page: this.paginacao.page - 1,
				size: this.paginacao.itemsPerPage,
			}
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			const page = await this.voucherService.findVouchers(this.paramsAtualizados, axiosConfig) 
			this.totalRegistros = page.totalElements
			this.listaDeCashback = page.content
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	@Watch('paginacao', { deep: true })
	async onPaginacaoChange(newVal, oldVal) {
		if (this.mostra && (newVal.page !== oldVal.page || newVal.itemsPerPage !== oldVal.itemsPerPage) && this.acaoDeCashbackId != null && this.acaoManutencao != null) {
			await this.buscar();
		}
	}

	@Watch('acaoManutencao')
	onAcaoManutencaoChange() {
		this.acaoDeCashbackId = null;
	}

}
